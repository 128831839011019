import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Chip, U21ChipProps } from 'app/shared/u21-ui/components';
import { useToggleAlertSidebar } from 'app/modules/sidebar/hooks';
import { IconFlag } from '@u21/tabler-icons';

export interface U21AlertChipProps
  extends Omit<U21ChipProps, 'id' | 'children'> {
  id: string | number;
  label: string;
}

export const U21AlertChip = (props: U21AlertChipProps) => {
  const { id, label, ...rest } = props;
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const toggleAlertSidebar = useToggleAlertSidebar();
  return (
    <U21Chip
      onClick={
        hasReadAlertsPermission
          ? (e) => {
              e.stopPropagation();
              toggleAlertSidebar(
                typeof id === 'number' ? id : parseInt(id, 10),
                e,
              );
            }
          : undefined
      }
      icon={<IconFlag />}
      {...rest}
    >
      {label}
    </U21Chip>
  );
};
