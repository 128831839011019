import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Chip, U21ChipProps } from 'app/shared/u21-ui/components';
import { useToggleTransactionSidebar } from 'app/modules/sidebar/hooks';
import { IconCoin } from '@u21/tabler-icons';

export interface U21TransactionChipProps
  extends Omit<U21ChipProps, 'id' | 'children'> {
  id: number | string;
  label: string;
}

export const U21TransactionChip = (props: U21TransactionChipProps) => {
  const { id, label, ...rest } = props;
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const toggleTransactionSidebar = useToggleTransactionSidebar();
  return (
    <U21Chip
      onClick={
        hasReadEventsPermission
          ? (e) => {
              e.stopPropagation();
              toggleTransactionSidebar(id, e);
            }
          : undefined
      }
      icon={<IconCoin />}
      {...rest}
    >
      {label}
    </U21Chip>
  );
};
