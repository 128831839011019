export enum WatchlistFeedTypes {
  PEP = 'PEP',
  SANCTIONS = 'Sanctions',
  ADVERSE_MEDIA = 'Adverse Media',
}

export enum WatchlistProviders {
  UNIT22 = 'Unit22', // Dummy provider so we don't accidentally trigger any screenings that cost money
  LEXISNEXIS = 'LexisNexis',
}

export enum WatchlistScenarioFormFields {
  NAME = 'name',
  ENTITY_TYPE = 'entity_type',
  MATCH_THRESHOLD = 'match_threshold',
  MATCH_FIELDS = 'match_fields',
  IS_SPECIFICATION_VALID = 'is_specification_valid',
  MUST_MATCH_FEED_FILTERS = 'must_match_feed_filters',
  FEED_FILTERS = 'feed_filters',
  PROVIDER = 'provider',
  EXCLUDE_EXPIRED_MATCHES = 'exclude_expired_matches',
  DYNAMIC_FACT_FILTER = 'dynamic_fact_filter',
}
