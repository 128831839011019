import { FullEntityResponse } from 'app/modules/entities/types';

import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { selectDLEnabledForEntityDetails } from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';

export const useEntityDetails = <TSelect = FullEntityResponse>(
  entityId: string,
  select?: (data: FullEntityResponse) => TSelect,
  enabled: boolean = true,
) => {
  const useCacheEndpoint = useSelector(selectDLEnabledForEntityDetails);
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );

  return useQuery({
    queryFn: () =>
      get<FullEntityResponse>(
        useCacheEndpoint
          ? `/entities/cached/${encodeURIComponent(entityId)}`
          : `/entities/${encodeURIComponent(entityId)}`,
      ),
    queryKey: ENTITY_QUERY_KEYS.getEntityDetails(entityId, useCacheEndpoint),
    select,
    staleTime: 10000,
    enabled: !!entityId && enabled && hasReadEntitiesPermission,
  });
};
