import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Chip, U21ChipProps } from 'app/shared/u21-ui/components';
import { useToggleActionEventSidebar } from 'app/modules/sidebar/hooks';
import { IconLock } from '@u21/tabler-icons';

export interface U21ActionEventChipProps
  extends Omit<U21ChipProps, 'id' | 'children'> {
  id: string | number;
  label: string;
}

export const U21ActionEventChip = (props: U21ActionEventChipProps) => {
  const { id, label, onClick, ...rest } = props;
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const toggleActionEventSidebar = useToggleActionEventSidebar();
  return (
    <U21Chip
      onClick={
        hasReadEventsPermission
          ? (e) => {
              if (onClick) {
                onClick(e);
              }

              e.stopPropagation();
              toggleActionEventSidebar(
                typeof id === 'number' ? id : parseInt(id, 10),
                e,
              );
            }
          : undefined
      }
      icon={<IconLock />}
      {...rest}
    >
      {label}
    </U21Chip>
  );
};
