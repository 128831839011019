import { SymbolTable } from 'app/modules/rulesAdvanced/lib/SymbolTable';
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

export const TXN_EVENT_SENDER_RECEIVER_ADVANCED_SYMBOLS: SymbolTable =
  Object.freeze({
    'sender_entity.cumulative_transaction_sum': {
      type: 'FIELD',
      field: 'sender_entity.cumulative_transaction_sum',
      label: `Entity / ${fromUpperToSentenceCase('sender_entity')} / ${fromUpperToSentenceCase('cumulative_transaction_sum')}`,
      model: 'txn_event',
      datatype: 'number',
      cardinality: 'many',
    },
    'receiver_entity.cumulative_transaction_sum': {
      type: 'FIELD',
      field: 'receiver_entity.cumulative_transaction_sum',
      label: `Entity / ${fromUpperToSentenceCase('receiver_entity')} / ${fromUpperToSentenceCase('cumulative_transaction_sum')}`,
      model: 'txn_event',
      datatype: 'number',
      cardinality: 'many',
    },
    'sender_entity.first_transaction': {
      type: 'FIELD',
      field: 'sender_entity.first_transaction',
      label: `Entity / ${fromUpperToSentenceCase('sender_entity')} / ${fromUpperToSentenceCase('first_transaction')}`,
      model: 'txn_event',
      datatype: 'datetime',
      cardinality: 'many',
    },
    'receiver_entity.first_transaction': {
      type: 'FIELD',
      field: 'receiver_entity.first_transaction',
      label: `Entity / ${fromUpperToSentenceCase('receiver_entity')} / ${fromUpperToSentenceCase('first_transaction')}`,
      model: 'txn_event',
      datatype: 'datetime',
      cardinality: 'many',
    },

    sender_id: {
      type: 'FIELD',
      field: 'sender_id',
      label: 'Transaction / Sender ID',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    receiver_id: {
      type: 'FIELD',
      field: 'receiver_id',
      label: 'Transaction / Receiver ID',
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    sender_source: {
      type: 'FIELD',
      field: 'sender_source',
      label: `Transaction / ${fromUpperToSentenceCase('sender_source')}`,
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    receiver_source: {
      type: 'FIELD',
      field: 'receiver_source',
      label: `Transaction / ${fromUpperToSentenceCase('receiver_source')}`,
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'sender_entity.phone_number.number': {
      type: 'FIELD',
      field: 'sender_entity.phone_number.number',
      label: `Entity / ${fromUpperToSentenceCase('sender_entity')} / ${fromUpperToSentenceCase('phone_number')} / Number`,
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'receiver_entity.phone_number.number': {
      type: 'FIELD',
      field: 'receiver_entity.phone_number.number',
      label: `Entity / ${fromUpperToSentenceCase('receiver_entity')} / ${fromUpperToSentenceCase('phone_number')} / Number`,
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'sender_entity.email_address.email_address': {
      type: 'FIELD',
      field: 'sender_entity.email_address.email_address',
      label: `Entity / ${fromUpperToSentenceCase('sender_entity')} / ${fromUpperToSentenceCase('email_address')}`,
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
    'receiver_entity.email_address.email_address': {
      type: 'FIELD',
      field: 'receiver_entity.email_address.email_address',
      label: `Entity / ${fromUpperToSentenceCase('receiver_entity')} / ${fromUpperToSentenceCase('email_address')}`,
      model: 'txn_event',
      datatype: 'string',
      cardinality: 'many',
    },
  });
