// Types
import { InvestigationActionPayload } from 'app/modules/investigations/types/requests';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';

// Utils
import { get, post } from 'app/shared/utils/fetchr';
import { consoleError } from 'app/shared/utils/console';
import { store } from 'app/store/configureStore';
import { selectDLEnabledForCaseDetail } from 'app/shared/featureFlags/selectors';

export const retrieveInvestigationsAlert = <TResponse>(
  payload: InvestigationsPayload,
) => {
  return post<TResponse>('/investigations/retrieve/alert', payload);
};

export const retrieveInvestigationsCase = <TResponse>(
  payload: InvestigationsPayload,
) => {
  const useNewEndpoint = selectDLEnabledForCaseDetail(store.getState());

  if (useNewEndpoint) {
    if (!payload?.object_ids?.[0]) {
      consoleError({
        error: 'No object_ids provided',
        info: { payload },
      });
      return Promise.reject(new Error('No object_ids provided'));
    }
    return get<TResponse>(`/cases/cached/${payload?.object_ids?.[0]}`);
  }
  return post<TResponse>('/investigations/retrieve/case', payload);
};

export const retrieveInvestigationsCases = <TResponse>(
  payload: InvestigationsPayload,
) => {
  return post<TResponse>('/investigations/retrieve/case-list', payload);
};

export const retrieveInvestigationAgentStats = (
  payload: InvestigationsPayload,
) => post('/investigations/agent-stats', payload);

export const performInvestigationAction = (
  payload: InvestigationActionPayload,
) => post<InvestigationsAlertResponse>('/investigations/action', payload);
