import React, { CSSProperties, ReactNode, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/system/colorManipulator';
import styled, { css } from 'styled-components';

// Components
import { IconChevronDown, IconChevronRight } from '@u21/tabler-icons';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import { U21NewFeatureLabel } from 'app/shared/u21-ui/components';

// Utils
import emptyFn from 'app/shared/utils/empty-fn';

// Theme
import typography from 'vendor/material-minimal/typography';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...typography.body2,
  height: 48,
  width: 180,
  textTransform: 'capitalize',
  paddingLeft: '18px',
  color: theme.palette.text.secondary,
  stroke: theme.palette.text.secondary,
  strokeWidth: 2,
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '&.isActiveRoot': {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const ListItemTextStyle = styled(ListItemText)<{ $newLabel: boolean }>(({
  $newLabel,
}) => {
  if ($newLabel) {
    return css`
      padding: 0px 10px;
      flex: 0 0 auto;
    `;
  }
  return css`
    padding-left: 10px;
    padding-right: 10px;
  `;
});

interface NavItemProps {
  level?: number;
  title?: string;
  href?: string;
  icon?: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
  onClick?: any;
  groupFirst?: boolean;
  newLabel?: boolean | string;
}

export default function SidebarItem({
  level = 0,
  title,
  href,
  icon,
  onClick = emptyFn,
  children,
  disabled = false,
  groupFirst = false,
  newLabel = false,
}: NavItemProps) {
  const [show, setShow] = useState(true);
  const style: CSSProperties = {};
  const featureFlags = useSelector(selectFeatureFlags);

  const showNewLabel =
    (typeof newLabel === 'boolean' && newLabel) ||
    (typeof newLabel === 'string' && featureFlags[newLabel]) ||
    false;

  if (level > 0) {
    // Sub item indentation
    style.paddingLeft = '45px';
  }
  if (groupFirst) {
    // space between groups
    style.marginTop = '48px';
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          disabled={disabled}
          disableGutters
          onClick={() => setShow((currShow) => !currShow)}
        >
          {show && <IconChevronDown />}
          {!show && <IconChevronRight />}

          <ListItemTextStyle
            disableTypography
            primary={title}
            $newLabel={showNewLabel}
          />
        </ListItemStyle>

        <Collapse in={show}>{children}</Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      // @ts-ignore
      to={href || {}}
      exact={false}
      disableGutters
      component={RouterLink}
      activeClassName="isActiveRoot"
      onClick={onClick || emptyFn}
      style={style}
    >
      {icon && icon}
      {title && (
        <ListItemTextStyle
          disableTypography
          primary={title}
          $newLabel={showNewLabel}
        />
      )}
      {title && showNewLabel && <NewLabel>New</NewLabel>}
    </ListItemStyle>
  );
}

const NewLabel = styled(U21NewFeatureLabel)`
  margin-left: auto;
  margin-right: 6px;
`;
