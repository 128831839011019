import { U21ChipProps } from 'app/shared/u21-ui/components';
import { U21ActionEventChip } from 'app/shared/u21-ui/components/dashboard/dataChip/U21ActionEventChip';
import { U21AlertChip } from 'app/shared/u21-ui/components/dashboard/dataChip/U21AlertChip';
import { U21EntityChip } from 'app/shared/u21-ui/components/dashboard/dataChip/U21EntityChip';
import { U21InstrumentChip } from 'app/shared/u21-ui/components/dashboard/dataChip/U21InstrumentChip';
import { U21TransactionChip } from 'app/shared/u21-ui/components/dashboard/dataChip/U21TransactionChip';

export enum U21DataChipType {
  ACTION_EVENT = 'ACTION_EVENT',
  ALERT = 'ALERT',
  ENTITY = 'ENTITY',
  INSTRUMENT = 'INSTRUMENT',
  TXN_EVENT = 'TXN_EVENT',
}

export type U21DataChipProps = {
  type: U21DataChipType;
  id: number | string;
  label: string;
} & Omit<U21ChipProps, 'id' | 'children'>;

export const U21DataChip = ({ id, label, type, ...rest }: U21DataChipProps) => {
  switch (type) {
    case U21DataChipType.ACTION_EVENT:
      return <U21ActionEventChip id={id} label={label} {...rest} />;
    case U21DataChipType.ALERT:
      return <U21AlertChip id={id} label={label} {...rest} />;
    case U21DataChipType.ENTITY:
      return <U21EntityChip id={id} name={label} {...rest} />;
    case U21DataChipType.INSTRUMENT:
      return <U21InstrumentChip id={id} label={label} {...rest} />;
    case U21DataChipType.TXN_EVENT:
      return <U21TransactionChip id={id} label={label} {...rest} />;
    default:
      return null;
  }
};
