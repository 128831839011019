import {
  AnnotationCondition,
  Transformation,
} from 'app/modules/dataMapping/responses';
import {
  AnnotationKeyType,
  PrimaryObject,
  U21Object,
} from 'app/modules/dataMapping/types';
import { DataSettingsKeyType } from 'app/modules/dataSettings/responses';

export enum PullBasedDataFileStatus {
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  ADDED = 'ADDED',
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
  CANCELLED = 'CANCELLED',
  NOT_ADDED = 'NOT_ADDED',
}

export enum PullBasedDataFileExecutionStatus {
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
  FINISHED_IN_FAILURE = 'FINISHED_IN_FAILURE',
  REAPED = 'REAPED',
}

export enum PullBasedDataFileExecutionFailureCode {
  UNPARSEABLE_FILE = 'UNPARSEABLE_FILE',
  INVALID_SCHEMA = 'INVALID_SCHEMA',
  STREAM_NOT_CONFIGURED = 'STREAM_NOT_CONFIGURED',
  UNKNOWN = 'UNKNOWN',
  MAX_RETRIES_EXCEEDED = 'MAX_RETRIES_EXCEEDED',
  NON_UTF8_ENCODING = 'NON_UTF8_ENCODING',
  CSV_PARSING_ERROR = 'CSV_PARSING_ERROR',
}

export interface PullBasedDataFileError {
  error_message: string;
  row_number?: number;
}

export enum ProcessingExceptionType {
  DIRECTIONALITY_ERROR = 'DIRECTIONALITY_ERROR',
  EXCESSIVE_OBJECT_ERROR = 'EXCESSIVE_OBJECT_ERROR',
}

export enum AnnotationTransformationExceptionType {
  INVALID_ID = 'INVALID_ID',
  INVALID_NUMBER = 'INVALID_NUMBER',
  INVALID_DATETIME = 'INVALID_DATETIME',
  INVALID_ENUM = 'INVALID_ENUM',
  INVALID_PATH = 'INVALID_PATH',
  INVALID_MIME_TYPE = 'INVALID_MIME_TYPE',
  TRANSFORMATION_ERROR = 'TRANSFORMATION_ERROR',
  CONDITION_ERROR = 'CONDITION_ERROR',
  CONDITIONAL_REQUIRED_FIELD_ERROR = 'CONDITIONAL_REQUIRED_FIELD_ERROR',
}

export interface BaseErrorInstance {
  raw_data: object;
  row_number: number;
}

export type ProcessingExceptionInstance = BaseErrorInstance;

export interface ConditionErrorValue {
  left: string[];
  operator: AnnotationCondition['operator'];
  right: string[];
}

export interface AnnotationTransformationExceptionInstance
  extends BaseErrorInstance {
  value: string | number | ConditionErrorValue | null;
}

interface BaseException<T, I> {
  type: T;
  count: number;
  errors: I[];
}

export type ProcessingException = BaseException<
  ProcessingExceptionType,
  ProcessingExceptionInstance
>;

export interface AnnotationTransformationException
  extends BaseException<
    AnnotationTransformationExceptionType,
    AnnotationTransformationExceptionInstance
  > {
  annotation: AnnotationKeyType | string;
  annotation_type: DataSettingsKeyType;
  columns: string[];
  transformation: Transformation['function'] | null;
}

export type AnnotationTransformationExceptionMap = Record<
  string,
  ProcessingException | AnnotationTransformationException
> & { object_type: U21Object; count: number };

// these are very different things but they conveniently have similar shape
export interface UnhandledProcessingExceptionInstance
  extends AnnotationTransformationExceptionInstance {
  value: string;
}
export interface PullBasedDataFileExecutionErrors {
  annotation_transformation_exceptions: Record<
    string,
    AnnotationTransformationExceptionMap
  >;
  unhandled_exceptions: {
    errors: UnhandledProcessingExceptionInstance[];
    count: number;
  };
  total_count: number;
}

export interface LegacyPullBasedDataFileError {
  error_message: string;
  row_number?: number;
}

export type LegacyPullBasedDataFileExecutionErrors =
  LegacyPullBasedDataFileError[];

export interface PullBasedDataFileLatestExecution {
  finished_at: string;
  queued_at: string;
  total_count: number;
  success_count: number;
  failure_count: number;
  skip_count: number;
  status: PullBasedDataFileExecutionStatus;
  failure_code: PullBasedDataFileExecutionFailureCode;
  id: number;
  errors:
    | PullBasedDataFileExecutionErrors
    | LegacyPullBasedDataFileExecutionErrors
    | null;
  is_dry_run: boolean;
}

export interface PullBasedDataFile {
  id: number;
  date_created: string;
  file_size: number;
  status: PullBasedDataFileStatus;
  stream_id: number;
  s3_path: string;
  file_name: string;
  bytes_processed: number | null;
  latest_execution: PullBasedDataFileLatestExecution | null;
  originator_bucket_id: number | null;
}

export enum FileStreamingSetting {
  DEFAULT = 'DEFAULT',
  CUSTOM_CSV = 'CUSTOM_CSV',
  FIXED_ROW_LENGTH = 'FIXED_ROW_LENGTH',
  FCRM_ALERTS_EXPORT = 'FCRM_ALERTS_EXPORT_CSV',
  BSA_TELLER_FILE = 'BSA_TELLER_FILE_CSV',
  X9_FILE = 'X9_FILE',
}

export enum FileValidationMode {
  FULL_FILE = 'full_file',
  ROW_BY_ROW = 'row_by_row',
}

export enum FileHeaderInfo {
  USE = 'USE',
  IGNORE = 'IGNORE',
  NONE = 'NONE',
}

export enum FileStreamingDelimiter {
  TAB = '\t',
  PIPE = '|',
  COMMA = ',',
  SPACE = ' ',
  NEW_LINE = '\n',
  SEMICOLON = ';',
}

export type FixedRowLengthConfiguration = Record<string, [number, number]>;
export interface FileStreamingConfiguration {
  FileHeaderInfo?: FileHeaderInfo;
  FieldDelimiter?: FileStreamingDelimiter;
  RecordDelimiter?: FileStreamingDelimiter;
  FixedRowLengthConfig?: FixedRowLengthConfiguration;
  NullValues?: string[];
}

export enum StreamMode {
  DATA_MAPPED = 'data_mapped',
  PASS_THROUGH = 'pass_through',
  MANAGED = 'managed',
}

export interface Stream {
  source_object_name: string;
  id: number;
  annotation_transformation_config_id?: string;
  description?: string;
  unit21_object?: PrimaryObject;
  file_count: number;
  is_mapped: boolean;
  updating_existing: boolean;
  file_streaming_setting: FileStreamingSetting;
  file_streaming_configuration: FileStreamingConfiguration;
  mode: StreamMode;
  file_validation_mode: FileValidationMode;
  bucket_id?: number;
  replication_path?: string;
  max_days_between_file_uploads: number | null;
  is_pgp_encrypted: boolean;
  pci_encrypted_keys: string[];
}

export interface PullBasedDataFilesPage {
  count: number;
  pull_based_datafiles: PullBasedDataFile[];
}

export interface Streams {
  streams: Stream[];
  count: number;
}

export interface PullBasedDataFilesPreview {
  top_level_keys: string[];
  data: Record<string, string>[];
}

export enum ReplicationBucketOwner {
  UNIT21 = 'UNIT21',
  CUSTOMER_OWNED = 'CUSTOMER_OWNED',
}

export enum ReplicationBucketStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface ReplicationBucketResponse {
  id: number;
  name: string;
  owner: ReplicationBucketOwner;
  created_at: string;
}

export interface ListReplicationBucketsResponse {
  count: number;
  buckets: ReplicationBucketResponse[];
}
export interface PgpEncryptionKey {
  public_key: string;
  created_at: string;
  expiry_at: string;
  is_active: boolean;
}

export interface GetPgpEncryptionKeysResponse {
  pgp_keys: PgpEncryptionKey[];
}
