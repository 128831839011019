import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';

import { createPortal } from 'react-dom';
import {
  createMultiSelectOptionItemProps,
  createU21FilterOptions,
  flattenOption,
  generatePopperStyleProp,
  sortByMatchedness,
} from 'app/shared/u21-ui/components/input/select/utils';
import {
  ForwardedRef,
  forwardRef,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import { useResizeDetector } from 'react-resize-detector';
import { isEqual } from 'lodash';
import emptyFn from 'app/shared/utils/empty-fn';
import styled from 'styled-components';

import { AdornmentButton } from 'app/shared/u21-ui/components/input/text-field/AdornmentButton';
import {
  AutocompleteTextField,
  StyledBackdrop,
} from 'app/shared/u21-ui/components/input/select/styles';
import { Autocomplete, InputAdornment } from '@mui/material';
import {
  ClearButton,
  Loading,
  OnHoverCopyButton,
  StartIconContainer,
} from 'app/shared/u21-ui/components/input/text-field/styles';
import { IconChevronDown, IconChevronUp } from '@u21/tabler-icons';
import {
  MultiSelectListbox,
  MultiSelectListboxProps,
} from 'app/shared/u21-ui/components/input/select/MultiSelectListbox';
import { U21Chip } from 'app/shared/u21-ui/components/display/U21Chip';
import {
  U21FlattenedSelectOptionProps,
  U21SelectNestedMenuProps,
  U21SelectValue,
  U21SelectProps,
} from 'app/shared/u21-ui/components/input/select/U21Select';
import { U21MenuItem } from 'app/shared/u21-ui/components/display/U21MenuItem';
import { SelectOptionItem } from 'app/shared/u21-ui/components/input/select/SelectOptionItem';

export interface U21MultiSelectProps<
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
> extends Omit<
    U21SelectProps<TValue, TClearable>,
    'onChange' | 'showInvalidValue' | 'value'
  > {
  selectAllTooltip?: string;
  getLimitTagsText?: (count: number) => string;
  limitTags?: number;
  onChange: (value: TValue[], e: SyntheticEvent) => void;
  selectAllEnabled?: boolean;
  sortByMatchednessDisabled?: boolean;
  value?: TValue[];
  copyToClipboardEnabled?: boolean;
}

const U21MultiSelectInner = <
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
>(
  props: U21MultiSelectProps<TValue, TClearable>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    autoFocus,
    backdrop,
    backdropProps,
    clearable = true,
    disabled,
    selectAllTooltip,
    endIcon,
    error,
    expand,
    sortByMatchednessDisabled = false,
    filterOptions = createU21FilterOptions<TValue>,
    getLimitTagsText = (count) => `+${count} more`,
    inputProps = {},
    inputValue,
    label,
    limitTags,
    loading = false,
    noOptionsText,
    onChange,
    onClose,
    onInputChange,
    options,
    placeholder,
    required,
    responsiveLength = false,
    searchable = true,
    selectAllEnabled,
    copyToClipboardEnabled = false,
    startIcon,
    optionLimit = 1000,
    value = [],
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const flattenedOptions: ReadonlyArray<
    U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps
  > = useMemo(
    () =>
      options.reduce<
        (U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps)[]
      >((acc, i) => {
        if (!i.optionType) {
          flattenOption<TValue>(i).forEach((each) => acc.push(each));
        } else {
          acc.push(i);
        }
        return acc;
      }, []),
    [options],
  );

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const valueSet = new Set();
      const duplicateValueSet = new Set();
      const requiredSet: TValue[] = [];
      const selectedValueSet = new Set(value);

      flattenedOptions.forEach((i) => {
        if (
          i.value === undefined &&
          Object.prototype.hasOwnProperty.call(i, 'required')
        ) {
          throw new Error(
            'Menu items with children cannot be required for U21MultiSelect.',
          );
        }

        if (!i.optionType && i.value !== undefined) {
          if (i.required) {
            requiredSet.push(i.value);
          }

          if (valueSet.has(i.value)) {
            duplicateValueSet.add(i.value);
          }
          valueSet.add(i.value);
        }
      });

      if (!requiredSet.every((e) => selectedValueSet.has(e))) {
        throw new Error(
          `All required options must be in the initial value for U21MultiSelect. Required options are ${JSON.stringify(requiredSet)}`,
        );
      }

      if (duplicateValueSet.size) {
        throw new Error(
          `U21Multiselect cannot have duplicate values: ${[
            ...duplicateValueSet,
          ].join(',')}`,
        );
      }
    }
  }, [flattenedOptions, value]);

  const flattenRequiredOptions = useMemo<
    U21FlattenedSelectOptionProps<TValue>[]
  >(
    () =>
      flattenedOptions.filter(
        (i): i is U21FlattenedSelectOptionProps<TValue> =>
          !i.optionType && i.value !== undefined && Boolean(i.required),
      ),
    [flattenedOptions],
  );

  const selectableOptions = useMemo(
    () =>
      flattenedOptions.filter(
        (
          i,
        ): i is MultiSelectListboxProps<
          TValue,
          TClearable
        >['selectableOptions'][number] =>
          !i.optionType && !i.children && !i.disabled,
      ),
    [flattenedOptions],
  );

  const actualValue = useMemo<U21FlattenedSelectOptionProps<TValue>[]>(
    () =>
      value.flatMap<U21FlattenedSelectOptionProps<TValue>>((v) =>
        flattenedOptions.filter(
          (i): i is U21FlattenedSelectOptionProps<TValue> => {
            if (!i.optionType && i.value !== undefined) {
              return isEqual(i.value, v);
            }
            return false;
          },
        ),
      ) ?? [],
    [flattenedOptions, value],
  );

  const requiredValue = useMemo<TValue[]>(
    () =>
      selectableOptions
        .filter((i) => i.required)
        .map((i) => i.value)
        .filter((i): i is TValue => i !== undefined),
    [selectableOptions],
  );

  const hasClearIcon = useMemo(
    () =>
      clearable && !disabled && value.length > flattenRequiredOptions.length,
    [clearable, disabled, flattenRequiredOptions.length, value.length],
  );

  // Force options container resize
  // https://github.com/mui-org/material-ui/issues/27670
  const { ref: textFieldRef } = useResizeDetector({ handleHeight: false });

  // force focus since clicking on start + end adornments don't cause focus
  useEffect(() => {
    if (open) {
      inputRef?.current?.focus();
    }
  }, [open]);

  // calls onChange and tracks the analytics
  const onChangeAnalyticsWrapper = (
    option: U21FlattenedSelectOptionProps<TValue>[],
    e: SyntheticEvent,
  ) => {
    trackEvent(AnalyticsEvents.U21MULTISELECT_ON_CHANGE, props, {}, option);
    onChange(
      option.map((i) => i.value).filter((i): i is TValue => i !== undefined),
      e,
    );
  };

  const allSelected = useMemo(() => {
    if (selectableOptions.length !== value.length) {
      return false;
    }
    // value can be nested so can't use set / object to reduce time complexity
    return selectableOptions.every((i) =>
      value.find((j) => isEqual(j, i.value)),
    );
  }, [selectableOptions, value]);

  return (
    <>
      {backdrop &&
        createPortal(
          <StyledBackdrop invisible {...backdropProps} open={open} />,
          document.body,
        )}
      <Autocomplete
        autoHighlight
        disabled={disabled}
        disableClearable={!clearable}
        filterOptions={(allOptions, state) => {
          const filteredOptions = filterOptions(allOptions, state);
          // filterOptions allows U21SelectOption to make it easier for devs to use
          // so turn U21SelectOption into U21FlattenedSelectOptionProps
          const flattened = filteredOptions.reduce<
            (U21SelectNestedMenuProps | U21FlattenedSelectOptionProps<TValue>)[]
          >((acc, i) => {
            // filter out U21SelectedNestedMenuProps and U21FlattenedSelectOptionProps
            if (i.optionType || 'values' in i) {
              acc.push(i);
            } else {
              flattenOption<TValue>(i).forEach((each) => acc.push(each));
            }
            return acc;
          }, []);
          if (sortByMatchednessDisabled) return flattened;
          const { inputValue: searchInputValue } = state;
          return sortByMatchedness<TValue>(flattened, searchInputValue);
        }}
        fullWidth={!responsiveLength}
        getLimitTagsText={getLimitTagsText}
        getOptionLabel={(option: U21FlattenedSelectOptionProps<TValue>) =>
          String(option.text)
        }
        inputValue={inputValue}
        limitTags={limitTags}
        // @ts-ignore mui types don't support custom props
        ListboxComponent={MultiSelectListbox}
        ListboxProps={
          {
            allSelected,
            selectAllTooltip,
            onChange,
            selectAllEnabled,
            selectableOptions,
            value,
            requiredValue,
            // satisfies w/ MultiSelectListboxProps for type-safety
            // but type as any since mui types don't support custom props
          } satisfies MultiSelectListboxProps<TValue, TClearable> as any
        }
        loading={loading}
        multiple
        noOptionsText={noOptionsText}
        onChange={(e, option: U21FlattenedSelectOptionProps<TValue>[]) => {
          onChangeAnalyticsWrapper(
            [
              ...new Set([
                ...flattenRequiredOptions,
                ...option.filter((opt) => !opt.disabled),
              ]),
            ],
            e,
          );
        }}
        onClose={(e, reason) => {
          onClose?.(e, reason);
          if (reason === 'blur' || reason === 'escape') {
            setOpen(false);
          }
        }}
        onInputChange={(e, newSearchValue, reason) => {
          onInputChange?.(e, newSearchValue, reason);
          if (reason === 'input' && !open) {
            setOpen(true);
          }
        }}
        onOpen={() => setOpen(true)}
        open={open}
        options={flattenedOptions}
        popupIcon={<IconChevronDown />}
        ref={ref}
        renderInput={(params) => {
          const {
            inputProps: inputInputProps,
            InputProps,
            size,
            ...restParams
          } = params;

          const startAdornment = [
            Boolean(startIcon) && (
              <StartIconContainer $disabled={disabled} key="icon">
                {startIcon}
              </StartIconContainer>
            ),
            InputProps.startAdornment,
          ].filter(Boolean);

          return (
            <StyledAutocompleteTextField
              {...restParams}
              responsiveLength={responsiveLength}
              autoFocus={autoFocus}
              error={error}
              InputLabelProps={{ required }}
              inputProps={{
                ...inputInputProps,
                ...inputProps,
                readOnly: !searchable,
                // use responsive length if searchable
                responsiveLength: searchable,
              }}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {copyToClipboardEnabled && value.length > 0 && (
                      <OnHoverCopyButton text={value.join(',')} />
                    )}
                    {hasClearIcon && (
                      <ClearButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onChangeAnalyticsWrapper(flattenRequiredOptions, e);
                          // use setTimeout to allow onChange to happen first
                          setTimeout(() => inputRef.current?.focus(), 0);
                        }}
                      />
                    )}
                    <Loading loading={loading} />
                    {!disabled && (
                      <AdornmentButton
                        aria-label="Open"
                        disabled={disabled}
                        icon={open ? <IconChevronUp /> : <IconChevronDown />}
                        onClick={() => setOpen(!open)}
                      />
                    )}
                    {endIcon}
                  </InputAdornment>
                ),
                startAdornment:
                  startAdornment.length > 0 ? (
                    <StartInputAdornment position="start">
                      {startAdornment}
                    </StartInputAdornment>
                  ) : undefined,
              }}
              inputRef={inputRef}
              label={label}
              loading={loading}
              onChange={emptyFn}
              // hide placeholder when value is populated
              placeholder={value && value.length ? '' : placeholder}
              ref={textFieldRef}
            />
          );
        }}
        renderOption={(optionProps, option, state) => {
          if (optionLimit && state.index >= optionLimit) {
            return null;
          }

          // Use 'type' prop as a type guard to differentiate bt select options and menu items
          if (!option.optionType) {
            // don't render nested options if search filter is empty
            if (option.isNestedOption && !state.inputValue) {
              return null;
            }

            // don't render non-leaf nodes if searching
            if (state.inputValue && option.children) {
              return null;
            }

            const {
              color,
              children,
              className,
              key,
              onClick,
              ...restOptionProps
            } = optionProps;
            return (
              <SelectOptionItem
                key={key}
                {...restOptionProps}
                {...createMultiSelectOptionItemProps(
                  option,
                  onChangeAnalyticsWrapper,
                  actualValue,
                )}
              />
            );
          }
          const { item, alignRight } = option;
          return (
            <U21MenuItem
              key={item.key || (item.text as string)}
              alignRight={alignRight}
              item={item}
              // disable menu focus because select menu will close when it loses focus
              menuAutoFocus={false}
              onClose={() => setOpen(false)}
            />
          );
        }}
        renderTags={(
          values: U21FlattenedSelectOptionProps<TValue>[],
          getTagProps,
        ) => {
          if (selectAllEnabled && allSelected) {
            return (
              <U21Chip
                key="all"
                color="primary"
                onDelete={
                  disabled ? undefined : (e) => onChange(requiredValue, e)
                }
                tooltip={selectAllTooltip}
                variant="ghost"
              >
                All
              </U21Chip>
            );
          }
          return values.map((i, index) => {
            const {
              key: drop,
              onDelete,
              ...chipProps
            } = getTagProps({ index });
            const isOptionRequired = Boolean(
              !i.optionType && i.value !== undefined && i.required,
            );

            return (
              <U21Chip
                // append random string at the end to avoid conflicting `1` key caused by the more text
                key={`${JSON.stringify(i.value)}-tag`}
                color={i.color}
                {...chipProps}
                onDelete={disabled || isOptionRequired ? undefined : onDelete}
              >
                {i.text}
                {i.required && <Asterisk>&thinsp;*</Asterisk>}
              </U21Chip>
            );
          });
        }}
        size="small"
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: generatePopperStyleProp({
              backdrop,
              expand,
              width: textFieldRef.current?.clientWidth,
            }),
          },
        }}
        value={actualValue}
        {...getDOMProps(rest)}
      />
    </>
  );
};

export const U21MultiSelect = forwardRef(U21MultiSelectInner);

const StyledAutocompleteTextField = styled(AutocompleteTextField)`
  .MuiAutocomplete-input.MuiAutocomplete-input.MuiAutocomplete-input {
    min-width: 100px;
  }

  .MuiInputAdornment-root {
    flex-wrap: wrap;
  }
`;

const StartInputAdornment = styled(InputAdornment)`
  max-width: 100%;
`;

const Asterisk = styled.b`
  color: ${(props) => props.theme.palette.error.main};
`;
