import { U21Button, U21TextFieldProps } from 'app/shared/u21-ui/components';
import { InputAdornment } from '@mui/material';
import {
  ClearButton,
  Loading,
  StartIconContainer,
  StyledTextField,
} from 'app/shared/u21-ui/components/input/text-field/styles';
import {
  EncryptedInput,
  EncryptedInputProps,
} from 'app/shared/u21-ui/components/input/text-field/EncryptedInput';
import {
  TYPES,
  U21EncryptedFieldProps,
} from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import { getDOMProps } from 'app/shared/utils/react';
import {
  forwardRef,
  Fragment,
  HTMLProps,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import useId from '@mui/utils/useId';
import { consoleWarn } from 'app/shared/utils/console';
import { IconPCI } from 'app/shared/u21-ui/components/input/text-field/IconPCI';
import { IconAlertTriangle } from '@u21/tabler-icons';
import styled from 'styled-components';

export interface U21EncryptedTextFieldProps
  extends Omit<
      HTMLProps<HTMLDivElement>,
      'label' | 'onBlur' | 'onChange' | 'onError' | 'onFocus' | 'size' | 'type'
    >,
    Pick<
      U21TextFieldProps,
      | 'clearable'
      | 'delay'
      | 'disabled'
      | 'endIcon'
      | 'error'
      | 'id'
      | 'InputLabelProps'
      | 'InputProps'
      | 'loading'
      | 'placeholder'
      | 'required'
    > {
  inputProps?: Omit<U21TextFieldProps['inputProps'], 'onBlur' | 'onFocus'>;
  onBlur?: () => void;
  onChange: (value: string | undefined) => void;
  onFocus?: () => void;
  encryptedFieldProps: U21EncryptedFieldProps;
  onError?: (error?: string) => void;
  type?: `${TYPES.TEXT}` | `${TYPES.TEXTAREA}`;
}

export const U21EncryptedTextField = forwardRef<
  HTMLDivElement,
  U21EncryptedTextFieldProps
>((props: U21EncryptedTextFieldProps, ref) => {
  const {
    clearable = true,
    delay,
    disabled,
    encryptedFieldProps,
    endIcon,
    error,
    id: idProp,
    InputLabelProps = {},
    inputProps = {},
    InputProps = {},
    loading = false,
    name,
    onChange,
    onError,
    placeholder,
    required,
    type = TYPES.TEXT,
    value,
    ...rest
  } = props;

  const id = useId(idProp);
  const isTextArea = type === TYPES.TEXTAREA;

  const [decryptionError, setDecryptionError] = useState(false);
  const [fieldFocus, setFieldFocus] = useState(false);
  const [fieldReady, setFieldReady] = useState(false);
  const [frameError, setFrameError] = useState(false);
  const [frameReady, setFrameReady] = useState(false);

  const frameLoading = frameError ? false : !frameReady || !fieldReady;

  const hasClearIcon =
    clearable &&
    !disabled &&
    (value === 0 || Boolean(value)) &&
    !frameLoading &&
    !frameError &&
    !isTextArea &&
    !decryptionError;

  const defaultEndAdornment = useMemo(() => {
    const adornments = [
      hasClearIcon && (
        <ClearButton
          key="clear"
          onClick={() => {
            onChange(undefined);
          }}
        />
      ),
      (loading || frameLoading) && <Loading key="loading" loading />,
      <Fragment key="end-icon">{endIcon}</Fragment>,
    ].filter(Boolean);
    if (adornments.length > 0) {
      return <InputAdornment position="end">{adornments}</InputAdornment>;
    }
    return undefined;
  }, [endIcon, frameLoading, hasClearIcon, loading, onChange]);

  // Let people know if there's an issue.
  useEffect(() => {
    if (!name) {
      consoleWarn('Encrypted field must have a name');
    }
  }, [name]);

  // used to force remount EncryptedInput
  const [key, forceUpdate] = useReducer((x) => x + 1, 0);

  const onReload = () => {
    setDecryptionError(false);
    setFieldReady(false);
    setFrameError(false);
    setFrameReady(false);
    forceUpdate();
  };

  return (
    <StyledTextField
      key={key}
      $loading={loading || frameLoading}
      $textarea={isTextArea}
      disabled={disabled}
      focused={fieldFocus}
      error={error || frameError || decryptionError}
      fullWidth
      helperText={
        decryptionError ? (
          <>
            Failed to load encrypted field. Please{' '}
            <U21Button color="primary" onClick={onReload} variant="text">
              load again
            </U21Button>
            {' or '}
            {/* Allows agent to clear the data in the field, helpful when field has non-token data */}
            <U21Button
              color="primary"
              onClick={() => {
                onChange(undefined);
                onReload();
              }}
              variant="text"
            >
              clear data
            </U21Button>
          </>
        ) : undefined
      }
      id={id}
      inputProps={
        {
          delay,
          encryptedFieldProps,
          frameError,
          frameLoading,
          frameReady,
          onReload,
          setDecryptionError,
          setFieldError: onError,
          setFieldFocus,
          setFieldReady,
          setFrameError,
          setFrameReady,
          type,
          ...inputProps,
          // onChange | value is injected by TextField
        } satisfies Omit<EncryptedInputProps, 'onChange' | 'value'>
      }
      InputLabelProps={{
        htmlFor: id,
        id: `${id}-label`,
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        inputComponent: EncryptedInput,
        endAdornment: InputProps.endAdornment || defaultEndAdornment,
        startAdornment: (
          <InputAdornment position="start">
            <StartIconContainer $disabled={disabled}>
              {frameError || decryptionError ? (
                <StyledIconAlertTriangle />
              ) : (
                <IconPCI />
              )}
            </StartIconContainer>
          </InputAdornment>
        ),
      }}
      name={name}
      onChange={(e) => {
        const { value: newValue } = e.target;
        onChange(newValue);
      }}
      ref={ref}
      required={required}
      placeholder={placeholder}
      type={type}
      value={value ?? ''}
      {...getDOMProps(rest)}
    />
  );
});

const StyledIconAlertTriangle = styled(IconAlertTriangle)`
  color: ${(props) => props.theme.palette.error.main};
`;
