import {
  AnnotationCondition,
  MathTransformationOperators,
  TypeHandling,
  ValueBasedDirectionality,
} from 'app/modules/dataMapping/responses';
import {
  AnnotationConstant,
  BaseDataMappingPair,
  PrimaryObject,
  SchemaValidation,
  U21Object,
  U21TypeToAnnotation,
} from 'app/modules/dataMapping/types';

export const COMPLETE_STATUS = {
  status: 'COMPLETE',
} as const;

export const DEFAULT_MODAL_PROPS = {
  type: 'none',
} as const;

export const RESERVED_PRIMARY_OBJECT_INTERNAL_NAME = 'self';

export const TABLE_TO_READABLE_NAME: Record<U21Object, string> = {
  txn_event: 'Transaction',
  action_event: 'Action',
  entity: 'Entity',
  instrument: 'Instrument',
  address: 'Address',
  tag: 'Tag',
  attachment: 'Attachment',
  alert: 'Alert',
  document: 'Document',
};

export const INVALID_SECONDARY_OBJECT_NAMES = {
  ...TABLE_TO_READABLE_NAME,
  // reserved word for primary object when configuring relationships
  self: RESERVED_PRIMARY_OBJECT_INTERNAL_NAME,
};

const many = true;

const REQUIRED_ENTITY_ANNOTATIONS: Record<string, AnnotationConstant> = {
  entity_id: {
    requirementType: 'update',
    label: 'entity ID',
  },
};

export const ENTITY_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_ENTITY_ANNOTATIONS,
  entity_type: {
    label: 'entity type',
    description: "Typically 'user' or 'business'",
  },
  entity_subtype: {
    label: 'entity subtype',
  },
  status: {
    label: 'status',
  },
  registered_at: {
    label: 'registered at',
  },
  first_name: {
    label: 'first name',
  },
  middle_name: {
    label: 'middle name',
  },
  last_name: {
    label: 'last name',
  },
  day_of_birth: {
    label: 'day of birth',
  },
  month_of_birth: {
    label: 'month of birth',
  },
  year_of_birth: {
    label: 'year of birth',
  },
  birth_date: {
    label: 'birth date',
  },
  ssn: {
    label: 'ssn',
  },
  business_name: {
    label: 'business name',
  },
  corporate_tax_id: {
    label: 'corporate tax ID',
  },
  account_holder_name: {
    label: 'account holder name',
  },
  registered_state: {
    label: 'registered state',
  },
  registered_country: {
    label: 'registered country',
  },
  doing_business_as: {
    label: 'doing business as',
  },
  website: {
    label: 'website',
  },
  email_addresses: {
    label: 'email address',
    many,
    description: 'Can be mapped multiple times',
  },
  phone_numbers: {
    label: 'phone number',
    many,
    description: 'Can be mapped multiple times',
  },
  ip_addresses: {
    label: 'IP address',
    many,
    description: 'Can be mapped multiple times',
  },
  client_fingerprints: {
    label: 'client fingerprint',
    many,
    description: 'Can be mapped multiple times',
  },
};

const REQUIRED_INSTRUMENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  instrument_id: {
    requirementType: 'update',
    label: 'instrument ID',
  },
};

export const INSTRUMENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_INSTRUMENT_ANNOTATIONS,
  instrument_type: {
    label: 'instrument type',
  },
  instrument_subtype: {
    label: 'instrument subtype',
  },
  source: {
    label: 'source',
    description: "Must be 'internal' or 'external'",
  },
  status: {
    label: 'status',
  },
  registered_at: {
    label: 'registered at',
  },
  entities: {
    label: 'entity ID',
    many,
    description: 'Can be mapped multiple times',
  },
  ip_addresses: {
    label: 'IP address',
    many,
    description: 'Can be mapped multiple times',
  },
  client_fingerprints: {
    label: 'client fingerprint',
    many,
    description: 'Can be mapped multiple times',
  },
  parent_instrument_id: {
    label: 'parent instrument id',
  },
  name: {
    label: 'instrument name',
  },
};

const REQUIRED_TXN_EVENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  event_id: {
    requirementType: 'update',
    label: 'event ID',
  },
  event_time: {
    requirementType: 'create',
    label: 'event time',
    notRequiredFor: [PrimaryObject.ALERT],
  },
  amount: {
    requirementType: 'create',
    label: 'amount',
    notRequiredFor: [PrimaryObject.ALERT],
  },
};

export const TXN_EVENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_TXN_EVENT_ANNOTATIONS,
  event_subtype: {
    label: 'event subtype',
  },
  status: {
    label: 'status',
  },
  sent_amount: {
    label: 'sent amount',
  },
  sent_currency: {
    label: 'sent currency',
  },
  received_amount: {
    label: 'received amount',
  },
  received_currency: {
    label: 'received currency',
  },
  exchange_rate: {
    label: 'exchange rate',
  },
  transaction_hash: {
    label: 'transaction hash',
  },
  usd_conversion_notes: {
    label: 'usd conversion notes',
  },
  internal_fee: {
    label: 'internal fee',
  },
  external_fee: {
    label: 'external fee',
  },
  ip_address: {
    label: 'IP address',
  },
  client_fingerprint: {
    label: 'client fingerprint',
  },
  parent_transaction_id: {
    label: 'parent transaction ID',
    many,
    description: 'Can be mapped multiple times',
  },
  parent_action_id: {
    label: 'parent action ID',
    many,
    description: 'Can be mapped multiple times',
  },
};

/**
 * Despite not being required in the API, these are
 * marked required in the FE to promote proper address usage.
 */
const REQUIRED_ADDRESS_ANNOTATIONS: Record<string, AnnotationConstant> = {
  street_name: {
    requirementType: 'create',
    label: 'street name',
  },
  city: {
    requirementType: 'create',
    label: 'city',
  },
  state: {
    requirementType: 'create',
    label: 'state',
  },
};

export const ADDRESS_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_ADDRESS_ANNOTATIONS,
  type: {
    label: 'type',
  },
  building_number: {
    label: 'building number',
  },
  unit_number: {
    label: 'unit number',
  },
  postal_code: {
    label: 'postal code',
  },
  country: {
    label: 'country',
  },
  us_address_string: {
    label: 'US full address',
  },
};

const REQUIRED_TAG_ANNOTATIONS: Record<string, AnnotationConstant> = {
  key: {
    requirementType: 'update',
    label: 'key',
  },
  value: {
    requirementType: 'update',
    label: 'value',
  },
};

export const TAG_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_TAG_ANNOTATIONS,
};

const REQUIRED_ACTION_EVENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  event_id: {
    requirementType: 'update',
    label: 'event ID',
  },
  event_time: {
    requirementType: 'create',
    label: 'timestamp',
    notRequiredFor: [PrimaryObject.ALERT],
  },
};

export const ACTION_EVENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_ACTION_EVENT_ANNOTATIONS,
  event_subtype: {
    label: 'type',
  },
  event_details: {
    label: 'description',
  },
  status: {
    label: 'status',
  },
  ip_address: {
    label: 'IP address',
  },
  client_fingerprint: {
    label: 'client fingerprint',
  },
};

const REQUIRED_ALERT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  alert_id: {
    requirementType: 'update',
    label: 'alert ID',
  },
  title: {
    requirementType: 'create',
    label: 'title',
  },
  created_at: {
    requirementType: 'create',
    label: 'created at',
  },
  status: {
    requirementType: 'create',
    label: 'status',
    description: "Either 'OPEN' or 'CLOSED'",
  },
};

export const ALERT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_ALERT_ANNOTATIONS,
  description: {
    label: 'description',
  },
  disposition: {
    label: 'disposition',
  },
  rule_ids: {
    label: 'rule ID',
    many,
    description: 'Can be mapped multiple times',
  },
  transaction_event_ids: {
    label: 'transaction event ID',
    many,
    description: 'Can be mapped multiple times',
  },
  action_event_ids: {
    label: 'action event ID',
    many,
    description: 'Can be mapped multiple times',
  },
};

const REQUIRED_DOCUMENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  document_id: {
    requirementType: 'update',
    label: 'document ID',
  },
  document_type: {
    requirementType: 'create',
    label: 'document type',
  },
};

export const DOCUMENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_DOCUMENT_ANNOTATIONS,
  state: {
    label: 'state',
  },
  country: {
    label: 'country',
  },
  issued_at: {
    label: 'issued_at',
  },
  expires_at: {
    label: 'expires_at',
  },
};

const REQUIRED_ATTACHMENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  file_s3_path: {
    requirementType: 'update',
    label: 'file S3 reference',
  },
  mime_type: {
    requirementType: 'update',
    label: 'attachment mime type',
  },
  media_type: {
    requirementType: 'update',
    label: 'attachment media type',
  },
};

export const ATTACHMENT_ANNOTATIONS: Record<string, AnnotationConstant> = {
  ...REQUIRED_ATTACHMENT_ANNOTATIONS,
};

export const U21_REQUIRED_TYPE_TO_ANNOTATIONS = {
  entity: REQUIRED_ENTITY_ANNOTATIONS,
  instrument: REQUIRED_INSTRUMENT_ANNOTATIONS,
  txn_event: REQUIRED_TXN_EVENT_ANNOTATIONS,
  address: REQUIRED_ADDRESS_ANNOTATIONS,
  tag: REQUIRED_TAG_ANNOTATIONS,
  attachment: REQUIRED_ATTACHMENT_ANNOTATIONS,
  action_event: REQUIRED_ACTION_EVENT_ANNOTATIONS,
  alert: REQUIRED_ALERT_ANNOTATIONS,
  document: REQUIRED_DOCUMENT_ANNOTATIONS,
};

export const U21_TYPE_TO_ANNOTATIONS: Record<string, U21TypeToAnnotation> = {
  entity: ENTITY_ANNOTATIONS,
  instrument: INSTRUMENT_ANNOTATIONS,
  txn_event: TXN_EVENT_ANNOTATIONS,
  address: ADDRESS_ANNOTATIONS,
  tag: TAG_ANNOTATIONS,
  action_event: ACTION_EVENT_ANNOTATIONS,
  alert: ALERT_ANNOTATIONS,
  document: DOCUMENT_ANNOTATIONS,
  attachment: ATTACHMENT_ANNOTATIONS,
};

export const U21_DATA_MAPPING_SCHEMA_KEYS: Record<string, AnnotationConstant> =
  {
    ...ENTITY_ANNOTATIONS,
    ...INSTRUMENT_ANNOTATIONS,
    ...TXN_EVENT_ANNOTATIONS,
    ...ADDRESS_ANNOTATIONS,
    ...TAG_ANNOTATIONS,
    ...ATTACHMENT_ANNOTATIONS,
    ...ACTION_EVENT_ANNOTATIONS,
    ...ALERT_ANNOTATIONS,
    ...DOCUMENT_ANNOTATIONS,
  };

export const TRANSFORMATION_TYPE_OPTIONS = [
  { text: 'Case format', value: 'case_format' },
  { text: 'Prefix', value: 'prefix' },
  { text: 'Postfix', value: 'postfix' },
  { text: 'Datetime', value: 'datetime_timezone' },
  { text: 'Find/replace all', value: 'replace_all' },
  { text: 'Find/replace first', value: 'replace_first' },
  { text: 'Value map', value: 'value_map' },
  { text: 'Remove escape sequences', value: 'remove_escape_sequence' },
  { text: 'Math', value: 'math' },
  { text: 'MD5 hash', value: 'md5_hash' },
  { text: 'Cast to boolean', value: 'boolean_cast' },
  { text: 'Cast to number', value: 'numeric_cast' },
  { text: 'ID clean', value: 'id_cleanup' },
  { text: 'JSON loads', value: 'json_loads' },
] as const;

export const CASE_FORMAT_OPTIONS = [
  { label: 'UPPER_CASE', value: 'uppercase' },
  { label: 'camelCase', value: 'camel_case' },
] as const;

export const DATETIME_TRANSFORMATION_CONFIG_OPTIONS = [
  { text: 'Detect automatically', value: 'auto' },
  { text: 'Epoch seconds', value: 'epoch_seconds' },
  { text: 'Epoch milliseconds', value: 'epoch_milliseconds' },
  { text: 'ISO 8601', value: 'iso_8601' },
  { text: 'INT96', value: 'int96' },
  { text: 'Strptime format', value: 'strptime' },
] as const;

export const DEFAULT_TYPE_HANDLING_VALUES: TypeHandling = {
  field_name: '',
  match_list: [],
  strategy: 'ignore_list',
};

export const DATA_MAPPING_PREVIEW_OPTIONS = {
  DATA: 'DATA',
  CONDITION: 'CONDITION',
  TRANSFORMATION: 'TRANSFORMATION',
  SCHEMA: 'SCHEMA',
} as const;

export const DATA_MAPPING_SELECTION_OPTIONS = {
  STREAM_VALUE: 'stream_value',
  HARD_CODED_VALUE: 'hard_coded_value',
  AGGREGATION: 'aggregation',
  METADATA_VALUE: 'metadata_value',
} as const;

export const DATA_MAPPING_AGGREGATION_METHODS = {
  SUMMATION: 'summation',
  STRING_CONCATENATION: 'string_concatenation',
  ADD_SPACE: 'add_space',
} as const;

export const AGGREGATION_SELECT_OPTIONS = [
  {
    value: DATA_MAPPING_AGGREGATION_METHODS.STRING_CONCATENATION,
    text: 'Concatenation',
    description: 'Concatenate all values into a single string',
  },
  {
    value: DATA_MAPPING_AGGREGATION_METHODS.SUMMATION,
    text: 'Summation',
    description: 'Attempt to cast all values to a number and sum them',
  },
  {
    value: DATA_MAPPING_AGGREGATION_METHODS.ADD_SPACE,
    text: 'Add space',
    description: 'Add a single space between non-null and non-empty values',
  },
];

export const BLANK_STREAM_ANNOTATION: BaseDataMappingPair = {
  value: {
    selection: {
      type: DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE,
      key: '',
    },
    transformations: [],
  },
  destination: '',
};

export const BLANK_HARDCODED_ANNOTATION: BaseDataMappingPair = {
  value: {
    selection: {
      type: DATA_MAPPING_SELECTION_OPTIONS.HARD_CODED_VALUE,
      value: '',
    },
    transformations: [],
  },
  destination: '',
};

export const SECONDARY_OBJECT_OPTIONS = [
  'entity',
  'instrument',
  'address',
  'tag',
  'attachment',
  'txn_event',
  'action_event',
  'document',
];

export const SECONDARY_OBJECT_OPTIONS_WITHOUT_CUSTOM_DATA_SUPPORT =
  new Set<`${U21Object}`>(['tag', 'address', 'document', 'attachment']);

export const BASE_SCHEMA_VALIDATION: SchemaValidation = {
  objects: {},
  relationships: null,
};

export const TRANSFORMATION_HELPER_TEXT = {
  prefix: 'Prefixes a string to the beginning of the origin value.',
  postfix: 'Postfixes a string to the end of the origin value.',
  replace_all: 'Replaces all occurrences of a string.',
  replace_first: 'Replaces the first occurrence of a string.',
  json_loads:
    'Attempts to load a string origin value as a JSON object. Option to specify a nested key with dot notation, and an index with bracket notation, e.g. "key1.key2[0]". Note that numbers cannot be used as keys, only as indices.',
  case_format: 'Changes the case formatting of the origin value.',
  md5_hash: 'Hashes the origin value using the MD5 algorithm.',
  numeric_cast: 'Converts the origin value to a number.',
  id_cleanup:
    "Removes leading or trailing whitespace. Replaces contiguous sequences of invalid characters with an underscore to generate an ID-like value (e.g., “Smith®,John\\n” → “Smith_John”). Note that these special characters are valid and will not be replaced: -_:.@!#$%&'*+/=?^`{|}~",
  value_map:
    "Checks for an exact match among those provided and replaces that row's value with the corresponding replacement value.",
  math: 'Performs simple arithmetic operations on a field. Automatically casts the origin value to a number.',
  remove_escape_sequence:
    'Removes all occurences of an escape sequence (e.g. a carriage return) from an origin value.',
  datetime_timezone:
    'Casts the origin value to a datetime object based on the specified input format with an option to localize the datetime to a chosen timezone.',
  boolean_cast:
    'Casts the origin value to a boolean. Valid inputs (True): True, T, 1, 1.0, 1.00. Valid inputs (False): False, F, 0, 0.0, 0.00. Valid inputs (Null): None or empty string. All other inputs are invalid.',
};

export const DIRECTIONALITY_TYPES = {
  HARD_CODED: 'hard_coded',
  CONDITIONAL: 'conditional',
  VALUE_BASED: 'value_based',
} as const;

export const DIRECTIONALITY_TYPES_TO_READABLE_NAME = {
  [DIRECTIONALITY_TYPES.CONDITIONAL]: 'Conditional',
  [DIRECTIONALITY_TYPES.HARD_CODED]: 'Hardcoded',
  [DIRECTIONALITY_TYPES.VALUE_BASED]: 'Value-based',
};

export const DIRECTIONALITY_TYPE_OPTIONS = {
  options: Object.values(DIRECTIONALITY_TYPES).map((d) => ({
    value: d,
    text: DIRECTIONALITY_TYPES_TO_READABLE_NAME[d],
  })),
};

export const DIRECTIONALITY_VALUES = {
  SENDING: 'sending',
  RECEIVING: 'receiving',
  NEITHER: 'neither',
  NONE: 'none',
} as const;

export const VALUE_BASED_DIRECTIONALITY_VALUES = {
  ...DIRECTIONALITY_VALUES,
  NONE: 'none',
} as const;

export const DIRECTIONALITY_VALUES_TO_READABLE_NAME = {
  [DIRECTIONALITY_VALUES.SENDING]: 'Sending',
  [DIRECTIONALITY_VALUES.RECEIVING]: 'Receiving',
  [DIRECTIONALITY_VALUES.NEITHER]: 'Both',
  [VALUE_BASED_DIRECTIONALITY_VALUES.NONE]: 'None',
};

export const BASE_VALUE_BASED_DIRECTIONALITY: Omit<
  ValueBasedDirectionality,
  'value'
> = {
  option: DIRECTIONALITY_TYPES.VALUE_BASED,
  raise_on_missing: true,
  [VALUE_BASED_DIRECTIONALITY_VALUES.SENDING]: [],
  [VALUE_BASED_DIRECTIONALITY_VALUES.RECEIVING]: [],
  [VALUE_BASED_DIRECTIONALITY_VALUES.NEITHER]: [],
  [VALUE_BASED_DIRECTIONALITY_VALUES.NONE]: [],
};

export const DIRECTIONALITY_OPTIONS = {
  options: Object.values(DIRECTIONALITY_VALUES).map((value) => ({
    value,
    text: DIRECTIONALITY_VALUES_TO_READABLE_NAME[value],
  })),
};

export const OPERATOR_HELPER_TEXT: Record<
  AnnotationCondition['operator'],
  string
> = {
  eq: 'Checks if the two values are equal.',
  ne: 'Checks if the two values are not equal.',
  lt: 'Casts both values to numbers and checks if the first value is less than the second value.',
  le: 'Casts both values to numbers and checks if the first value is less than or equal to the second value.',
  gt: 'Casts both values to numbers and checks if the first value is greater than the second value.',
  ge: 'Casts both values to numbers and checks if the first value is greater than or equal to the second value.',
  regex_match: 'Checks if the value matches the specified regular expression.',
  value_present: 'Checks if the value has any input. Whitespace is ignored',
  value_absent:
    'Checks if the value is null, empty, or consists of only whitespace.',
  is_true:
    'Casts the value to a boolean to check if it is true. Accepted inputs: True, T, 1, 1.0, 1.00. Case insensitive.',
  is_false:
    'Casts the value to a boolean to check if it is false. Accepted inputs: False, F, 0, 0.0, 0.00. Case insensitive.',
  is_in: 'Checks if the value is in the specified list. Case sensitive.',
  is_not_in:
    'Checks if the value is not in the specified list. Case sensitive.',
};

export const OPERATOR_OPTIONS = {
  options: [
    {
      value: 'eq',
      text: 'is equal to',
    },
    {
      value: 'ne',
      text: 'is not equal to',
    },
    {
      value: 'lt',
      text: 'is less than',
    },
    {
      value: 'le',
      text: 'is less than or equal to',
    },
    {
      value: 'gt',
      text: 'is greater than',
    },
    {
      value: 'ge',
      text: 'is greater than or equal to',
    },
    {
      value: 'regex_match',
      text: 'matches regular expression',
    },
    {
      value: 'value_present',
      text: 'is present',
    },
    {
      value: 'value_absent',
      text: 'is not present',
    },
    {
      value: 'is_true',
      text: 'is true',
    },
    {
      value: 'is_false',
      text: 'is false',
    },
    {
      value: 'is_in',
      text: 'is one of',
    },
    {
      value: 'is_not_in',
      text: 'is not one of',
    },
  ],
};

export const SINGLETON_OPERATOR_SET = new Set([
  'value_present',
  'value_absent',
  'is_true',
  'is_false',
]);

export const MATH_OPERATORS_TO_OPTION_MAP: Record<
  MathTransformationOperators,
  { text: string; description: string }
> = {
  [MathTransformationOperators.ADD]: { text: '+', description: 'add' },
  [MathTransformationOperators.SUBTRACT]: {
    text: '−',
    description: 'subtract',
  },
  [MathTransformationOperators.MULTIPLY]: {
    text: '×',
    description: 'multiply by',
  },
  [MathTransformationOperators.DIVIDE]: { text: '÷', description: 'divide by' },
};

export const TYPED_ANNOTATION_OPTION_PREFIX = 'typed-annotation:';
export const CUSTOM_ANNOTATION_OPTION_PREFIX = 'custom-data-annotation:';

export const SINGLETON_TRANSFORMATIONS = new Set([
  'md5_hash',
  'numeric_cast',
  'id_cleanup',
  'boolean_cast',
]);

export const DATA_MAPPING_FILE_METADATA_OPTIONS = {
  FILE_NAME: 'file_name' as const,
  FILE_UPLOAD_TIME: 'file_upload_time' as const,
};
