import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';

import { U21Chip, U21ChipProps } from 'app/shared/u21-ui/components';
import { useToggleInstrumentSidebar } from 'app/modules/sidebar/hooks';
import { IconCreditCard } from '@u21/tabler-icons';

export interface U21InstrumentChipProps
  extends Omit<U21ChipProps, 'id' | 'children'> {
  id: number | string;
  label: string;
}

export const U21InstrumentChip = (props: U21InstrumentChipProps) => {
  const { id, label, ...rest } = props;
  const hasReadInstrumentsPermission = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const toggleInstrumentSidebar = useToggleInstrumentSidebar();
  return (
    <U21Chip
      onClick={
        hasReadInstrumentsPermission
          ? (e) => {
              e.stopPropagation();
              toggleInstrumentSidebar(id, e);
            }
          : undefined
      }
      icon={<IconCreditCard />}
      {...rest}
    >
      {label}
    </U21Chip>
  );
};
