import {
  GetEntitiesPayload,
  RetrieveEntityTxnsPayload,
  RetrieveEntityActionEventsPayload,
  RetrieveEntityCasesPayload,
  RetrieveEntitySarsPayload,
} from 'app/modules/entitiesRefresh/requests';
import { PaginationPayload } from 'app/shared/pagination/models';

const BASE = ['entities'] as const;

export const ENTITY_QUERY_KEYS = {
  getEntities: (payload: GetEntitiesPayload) => [...BASE, payload] as const,
  getEntityDetails: (entityId: string, useCacheEndpoint: boolean) =>
    [...BASE, entityId, useCacheEndpoint] as const,
  getTransactionHistogram: (entityId: string) =>
    [...BASE, 'txn-histogram', entityId] as const,
  getTransactionSummary: (entityId: string) =>
    [...BASE, 'transaction-summary', entityId] as const,
  getTransactions: (args: RetrieveEntityTxnsPayload & { entityId: string }) =>
    [...BASE, 'transactions', args] as const,
  getActionEventsHistogram: (entityId: string) =>
    [...BASE, 'action-events-histogram', entityId] as const,
  getActionEvents: (
    args: RetrieveEntityActionEventsPayload & { entityId: string },
  ) => [...BASE, 'action-events', args] as const,
  getEntityAlerts: (
    args: Partial<PaginationPayload & { entityExternalId: string }>,
  ) => [...BASE, 'entity-alerts', args] as const,
  getEntityCases: (args: RetrieveEntityCasesPayload) =>
    [...BASE, 'entity-cases', args] as const,
  getEntitySars: (args: RetrieveEntitySarsPayload) =>
    [...BASE, 'entity-sars', args] as const,
  getEntityConsortiumHits: (entityId: string) =>
    [...BASE, 'consortium', entityId] as const,
  getEntitiesConsortiumHits: (entityExternalIds: string[]) =>
    [...BASE, 'consortium', entityExternalIds] as const,
  getEntityBlockedReasons: (externalId: string) =>
    [...BASE, 'blocked-reasons', externalId] as const,
  getPaginatedSilencedRules: (
    externalID: string,
    payload?: PaginationPayload,
  ) => {
    if (payload === undefined) {
      return [...BASE, 'silenced-rules', externalID] as const;
    }
    return [...BASE, 'silenced-rules', externalID, payload] as const;
  },
  getEntityNotes: (entityId: string) =>
    [...BASE, 'entity-notes', entityId] as const,
  getEntityGeolocations: (entityId: string) =>
    [...BASE, 'geolocations', entityId] as const,
};
