/**
 * Add the feature you are releasing to this list
 */
export const GA_FEATURES = [
  'PLACEHOLDER',
  'ALERT_SIDE_BY_SIDE',
  'CASE_SIDE_BY_SIDE',
  'NETWORK_ANALYSIS',
] as const;

export type GaFeatureTypes = (typeof GA_FEATURES)[number];

/**
 *  Increase Version number by 1 when you want to show new feature to user again.
 *  When adding new feature start it with version 1.
 */
export const FEATURE_CURRENT_VERSION: Record<GaFeatureTypes, number> = {
  PLACEHOLDER: 1,
  ALERT_SIDE_BY_SIDE: 1,
  CASE_SIDE_BY_SIDE: 1,
  NETWORK_ANALYSIS: 1,
};
export const GA_BANNER_FEATURE_DISABLED_LS_PREFIX =
  '__GA_BANNER_FEATURE_DISABLED_';

// No duplicates test
if (process.env.NODE_ENV === 'development') {
  (() => {
    const gaFeaturesSet = new Set(GA_FEATURES);
    if (gaFeaturesSet.size !== GA_FEATURES.length) {
      throw Error('Duplicate GA feature name added');
    }
  })();
}
