import {
  ReactElement,
  HTMLProps,
  forwardRef,
  RefObject,
  ReactNode,
} from 'react';

import { Tooltip, Popper } from '@mui/material';

import { getDOMProps } from 'app/shared/utils/react';

import styled from 'styled-components';

export interface U21TooltipProps
  extends Omit<HTMLProps<HTMLDivElement>, 'ref'> {
  children: ReactElement;
  disabled?: boolean;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  ref?: RefObject<HTMLElement>;
  tooltip: ReactNode;
  enterDelay?: number;
  enterNextDelay?: number;
}

export const U21Tooltip = forwardRef<HTMLElement, U21TooltipProps>(
  (
    {
      children,
      disabled,
      placement = 'top',
      tooltip,
      enterDelay,
      enterNextDelay,
      ...rest
    }: U21TooltipProps,
    ref,
  ) => {
    return (
      <Tooltip
        title={
          tooltip && !disabled ? (
            <TooltipContainer>{tooltip}</TooltipContainer>
          ) : undefined
        }
        placement={placement}
        enterDelay={enterDelay}
        enterNextDelay={enterNextDelay}
        arrow
        PopperComponent={StyledPopper}
        PopperProps={getDOMProps(rest)}
        ref={ref}
      >
        {children}
      </Tooltip>
    );
  },
);

const StyledPopper = styled(Popper)`
  .MuiTooltip-tooltip {
    max-width: 250px;
  }
`;

const TooltipContainer = styled.div`
  ${(props) => props.theme.typography.body2}
`;
