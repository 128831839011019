import styled, { css } from 'styled-components';

import { AdornmentButton } from 'app/shared/u21-ui/components/input/text-field/AdornmentButton';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { IconX } from '@u21/tabler-icons';
import { TextField } from '@mui/material';
import { U21CopyButton, U21TextFieldProps } from 'app/shared/u21-ui/components';

export const Loading = styled(U21Loading).attrs({
  delay: 0,
  variant: 'spinner',
})`
  padding: 4px;
`;

// typescript doesn't like this for some reason
export const ClearButton: any = styled(AdornmentButton).attrs({
  'aria-label': 'Clear',
  icon: <IconX />,
})`
  visibility: hidden;
`;

export const OnHoverCopyButton = styled(U21CopyButton)`
  visibility: hidden;

  .tabler-icon-check {
    visibility: visible;
  }
`;

export const StartIconContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px;

  color: ${(props) => props.theme.palette.grey[props.$disabled ? 500 : 600]};
`;

interface TextFieldStyleProps {
  $loading?: boolean;
  $responsiveLength?: boolean;
  $textarea?: boolean;
  $textTransform?: U21TextFieldProps['textTransform'];
}

export const StyledTextField = styled(TextField)<TextFieldStyleProps>`
  .MuiInputBase-root {
    min-height: 40px;
    ${(props) =>
      props.$textarea
        ? css`
            padding: 8.5px 6px;
            align-items: start;
          `
        : css`
            padding: 0 6px;
          `}

    ${(props) => {
      const { $loading, disabled } = props;
      let cursor;
      if ($loading) {
        cursor = 'progress';
      } else if (disabled) {
        cursor = 'not-allowed';
      }

      return (
        cursor &&
        css`
          cursor: ${cursor};

          .MuiInputBase-input {
            cursor: ${cursor};
          }
        `
      );
    }}
  }

  .MuiInputBase-input {
    ${(props) =>
      !props.InputProps?.startAdornment &&
      css`
        padding-left: 6px;
      `}

    ${(props) =>
      props.$textarea
        ? css`
            resize: vertical;
          `
        : css`
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}

        ${(props) =>
      props.$textTransform
        ? css`
            text-transform: ${props.$textTransform};

            ::placeholder {
              text-transform: none;
            }
          `
        : css``}
  }

  .MuiInputAdornment-root {
    height: unset;
    max-height: unset;
  }

  /* make space between adornment + text 8px total */

  .MuiInputAdornment-positionEnd {
    margin-left: 4px;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 4px;
  }

  .Mui-focused ${ClearButton} {
    visibility: visible;
  }

  .Mui-focused ${OnHoverCopyButton} {
    visibility: visible;
  }

  :hover {
    ${ClearButton} {
      visibility: visible;
    }
    ${OnHoverCopyButton} {
      visibility: visible;
    }
  }

  .MuiFormLabel-asterisk {
    color: ${(props) => props.theme.palette.error.main};
  }

  /* fix bug introduced by node_modules/antd/dist/antd.css where legend is set to 100% */
  ${(props) =>
    !props.label &&
    css`
      .MuiOutlinedInput-notchedOutline > legend {
        width: 0;
      }
    `}

  ${(props) =>
    props.$responsiveLength
      ? css`
          max-width: 100%;
        `
      : css``}
`;
